import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import ImageButton from '../Components/ImageButton';
import { useHistory } from 'react-router-dom';
import { Page } from '../Const/Page';
import { EnglishIcon, MandarinIcon } from '../Components/Icons';
import { GetBodyFontClass, GetHeaderFontClass, GotoTop } from '../Helper/Helper';

export default function ServicePage(){
    const history = useHistory();
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('service.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('service.subTitle')}</p>
        </ImgCenteredCaption>
        <p className='my-10 text-sm md:text-base text-center'>{t("service.description")}</p>
        <div className='flex flex-wrap justify-center'>
            <ImageButton className={`w-2/3 md:w-1/3 mx-5 md:mx-3 ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/emhall.jpg' onClick={(e)=>{history.push(Page.engService);GotoTop(e)}}>
                <EnglishIcon className='!text-sky-300'></EnglishIcon>{t('service.buttons.em.title')}
            </ImageButton>
            <ImageButton className={`w-2/3 md:w-1/3 mx-5 md:mx-3 mt-3 md:mt-0 ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/mainhall.jpg'onClick={(e)=>{history.push(Page.chineseService);GotoTop(e)}}>
                <MandarinIcon className='!text-rose-500'></MandarinIcon>{t('service.buttons.bilingual.title')}
            </ImageButton>
        </div>
    </div>
}
